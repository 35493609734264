import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import HeaderDate from './HeaderDate'
import Logo from './Logo'
import Menu from './Menu'
import MobileMenu from './MobileMenu'
import { useContext, useEffect, useState } from 'react'
import { SettingsContext } from '../context/settings'
import { getCookie } from 'cookies-next'
import Image from 'next/image'
import Router from 'next/router'

const TOKEN_NAME = 'peakload-token'
const UserDropdown = dynamic(() => import('./UserDropdown'))
import LoginIcon from '../../public/login-icon.svg'

function Header() {
  const router = useRouter()
  const settings = useContext(SettingsContext)
  const menuOptions = settings?.menus.main || []

  const [token, setToken] = useState<string | null>(null)

  // Use useEffect to set the token only on the client-side
  useEffect(() => {
    const clientToken = getCookie(TOKEN_NAME) as string | null
    setToken(clientToken)
  }, [])

  return (
    <header
      className={`sticky top-0 left-0 z-20 w-full bg-white lg:block ${!token ? 'border-b' : ''}`}
    >
      <div className="header-top">
        <MobileMenu options={menuOptions} currentPath={router.asPath} token={token} />
        <HeaderDate className="hidden xl:block" />
        <Logo isHeader />
        {!token && (
          <div className='xl:hidden lg:hidden md:hidden visible w-10 text-right' onClick={() => Router.push('/login')}>
            <Image src={LoginIcon} alt="Login icon" width={20} height={20} />
          </div>
        )}
        <div className="user-dropdown">
          {/* @ts-ignore */}
          <UserDropdown />
        </div>
      </div>
      {/* Render the Menu only when the token is available */}
      {token && (
        <Menu options={menuOptions} currentPath={router.asPath} />
      )}
      <style jsx>{`
        .header-top {
          @apply relative mx-auto flex h-[60px] max-w-[1440px] items-center justify-between px-4 md:h-[100px] xl:px-8;
        }
        .user-dropdown {
          @apply hidden flex-row-reverse items-center gap-2 lg:flex lg:flex-1;
        }
        .border-b {
          border-bottom: 1px solid #000;
        }
      `}</style>
    </header>
  )
}

export default Header
